import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

/*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

// Pages
import Homepage from './App';
import PrivacyPolicy from './routers/privacy';

import Error404 from './routers/404'
import TestHero from './routers/hero';

function Routes_() {
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            {/*Development*/}
            <Route path="/dev/hero" element={<TestHero />} />

            <Route
                path="*"
                element={
                    <Error404 />
                }
            />
        </Routes>
    )
}


const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrateRoot(rootElement,
        <React.StrictMode>
            <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
                <BrowserRouter>
                    <Routes_ />
                </BrowserRouter>
            </MantineProvider>
        </React.StrictMode>
    );
} else {
    const root = ReactDOM.createRoot(
        rootElement
    );
    root.render(
        <React.StrictMode>
            <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
                <BrowserRouter>
                    <Routes_ />
                </BrowserRouter>
            </MantineProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
