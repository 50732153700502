import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppBar from './components/AppBar'
import Hero from './components/homepage/Hero';
import Features from './components/homepage/Features'
import Footer from './components/Footer';

function App() {
    return (
        <div className="App">
            <AppBar />
            <Hero />
            <Features />
            <Footer />
        </div>
    );
}

export default App;
