import { ReactComponent as wordmark } from '../wordmark.svg'

export default function Wordmark(props: { size?: number }) {
    return (
        <span style={{ display: 'flex', alignItems: 'center' }} >
            <img height={props.size} src="/icon.svg" alt="SilenceVPN Logo" />
            <span style={{
                fontStyle: 'italic',
                fontVariant: 'normal',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontSize: '15px',
                fontFamily: 'TeXGyreHeros',
                fontVariantLigatures: 'normal',
                fontVariantCaps: 'normal',
                fontVariantNumeric: 'normal',
                fontVariantEastAsian: 'normal',
                whiteSpace: 'pre',
                display: 'inline',
                color: '#ffffff',
                stroke: 'none',
                strokeWidth: 15.0312,
                strokeDasharray: 'none',
                //transform: 'matrix(12.000017,0,0,12,-3137.8006,34.797309)'
            }} >SilenceVPN</span></span>
    )
}