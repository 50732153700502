import { createStyles, Text, SimpleGrid, Container } from '@mantine/core';
import { IconTruck, IconCertificate, IconCoin, TablerIcon } from '@tabler/icons';

const useStyles = createStyles((theme) => ({
    feature: {
        position: 'relative',
        paddingTop: theme.spacing.xl,
        paddingLeft: theme.spacing.xl,
    },

    overlay: {
        position: 'absolute',
        height: 100,
        width: 160,
        top: 0,
        left: 0,
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        zIndex: 1,
    },

    content: {
        position: 'relative',
        zIndex: 2,
    },

    icon: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
}));

interface FeatureProps extends React.ComponentPropsWithoutRef<'div'> {
    icon: TablerIcon;
    title: string;
    description: string;
}

function Feature({ icon: Icon, title, description, className, ...others }: FeatureProps) {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.feature, className)} {...others}>
            <div className={classes.overlay} />

            <div className={classes.content}>
                <Icon size={38} className={classes.icon} stroke={1.5} />
                <Text weight={700} size="lg" mb="xs" mt={5} className={classes.title}>
                    {title}
                </Text>
                <Text color="dimmed" size="sm">
                    {description}
                </Text>
            </div>
        </div>
    );
}

const mockdata = [
    {
        icon: IconTruck,
        title: 'Privacy is a universal right',
        description:
            'We provide users with 100% privacy, we do not have any identifiable information about you and we keep 0 logs.',
    },
    {
        icon: IconCertificate,
        title: 'Premium quality',
        description:
            'The SilenceVPN client is available for Windows, MacOS and Linux. We have an uptime of 99,9%',
    },
    {
        icon: IconCoin,
        title: 'Simple pricing',
        description:
            'SilenceVPN\'s pricing is very simple. We have 0 hidden costs and do not automatically charge your card.',
    },
];

export default function Features() {
    const items = mockdata.map((item) => <Feature {...item} key={item.title} />);

    return (
        <Container mt={30} mb={30} size="lg">
            <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50}>
                {items}
            </SimpleGrid>
        </Container>
    );
}